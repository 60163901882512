<nav [ngClass]="{ open: open }" [hidden]="!featureService.disableNewNavigation && area === 'Network'">
    <div *ngIf="area === 'Support' || area === 'Search' || area === 'Super'" class="title">
        {{ pageArea }}
    </div>
    <div
        *ngIf="
            area === 'Organization' &&
            !billingStatusInvalid &&
            ((billingEnabledService.billingEnabled && tenants.length === 1) || !authorizationService.canListTenants())
        "
        class="title"
    >
        Org Management
    </div>
    <div
        (click)="toggleLock()"
        [ngClass]="{ locked: menuService.locked }"
        class="lock"
        id="MenuLock"
        title="Lock Menu Open"
    ></div>

    <div
        (clickOutside)="closeNetworkList()"
        *ngIf="area === 'Network' && authorizationService.canListNetworks()"
        class="networksArea"
    >
        <div class="networks">
            <div (click)="toggleList($event)" class="currentNetwork" id="currentNetwork">
                {{ currentNetwork.name }}
            </div>
            <div [ngClass]="{ opened: isOpened }" class="shadow" id="Networks">
                <div class="row">
                    <div (click)="toggleList($event)" class="currentNetwork">
                        {{ currentNetwork.name }}
                    </div>
                </div>
                <div id="ListArea">
                    <div class="search row">
                        <input
                            (click)="stopProp($event)"
                            [(ngModel)]="filterString"
                            id="SearchNetworks"
                            placeholder="Type to Filter"
                            type="text"
                        />
                    </div>
                    <div class="headerNetworkList row">
                        <div
                            (click)="setNetwork(networkModel)"
                            *ngFor="let networkModel of allNetworks | tableSearch: filterString | sortby: 'name'"
                            class="selectNetwork row"
                        >
                            <div class="status s{{ networkModel.status }}" id="nStatList{{ networkModel.id }}">
                                <div class="anim5"></div>
                            </div>
                            {{ networkModel.name }}
                        </div>

                        <div
                            (click)="addNetwork()"
                            *ngIf="authorizationService.canCreateNetworks()"
                            class="item row"
                            id="AddNetworkButton"
                        >
                            <div class="icon append icon-BigSoftPlus"></div>
                            <div class="content">Add a New Network</div>
                        </div>
                    </div>
                    <!--          <div *ngIf="!featureService.experimentalFeatures" id="ManageNetworkButton" class="navItem" routerLink="/networks">Manage Networks</div>-->
                    <div class="navItem" id="ManageNetworkButton" routerLink="/networks">Manage Networks</div>
                </div>
            </div>
        </div>
    </div>
    <div
        (clickOutside)="closeTenantList()"
        *ngIf="
            area === 'Organization' &&
            (!billingEnabledService.billingEnabled || tenants.length > 1) &&
            authorizationService.canListTenants() &&
            (!this.isSelfService || authorizationService.isSupportUser)
        "
        class="tenantArea"
    >
        <div class="tenants">
            <div (click)="toggleListTenant($event)" class="currentTenant" id="currentTenant">
                {{ currentTenant.name }}
            </div>
            <div [ngClass]="{ opened: isOpenedTenant }" class="shadow" id="Tenants">
                <div class="row">
                    <div (click)="toggleListTenant($event)" class="currentTenant">
                        {{ currentTenant.name }}
                    </div>
                </div>
                <div id="ListArea">
                    <div class="search row">
                        <input
                            (click)="stopProp($event)"
                            [(ngModel)]="filterString"
                            id="SearchTenants"
                            placeholder="Type to Filter"
                            type="text"
                        />
                    </div>
                    <div class="headerTenantList row">
                        <div
                            (click)="setTenant(tenantModel)"
                            *ngFor="let tenantModel of tenants | tableSearch: filterString | sortby: 'name'"
                            [ngClass]="{ selected: currentTenant.id === tenantModel.id }"
                            class="selectNetwork row"
                        >
                            <div class="orgStatus s300" id="nStatList{{ tenantModel.id }}">
                                <div class="anim5"></div>
                            </div>
                            {{ tenantModel.name }}
                        </div>
                        <div
                            (click)="addTenant()"
                            *ngIf="authorizationService.canCreateTenant()"
                            class="item row"
                            id="AddTenantButton"
                        >
                            <div class="icon append icon-BigSoftPlus"></div>
                            <div class="content">Add a New Organization</div>
                        </div>
                    </div>
                    <div [routerLink]="Organizations" class="navItem" id="ManageTenantsButtons">
                        Manage Organizations
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="{ open: area === 'Network' }" class="navArea" id="NetworkNavigation">
        <div [ngClass]="{ logoEnabled: brandingModeService.logosAvailable }" class="navList menuscroll">
            <div *ngIf="!isZiti" [ngClass]="{ selected: url === '/main' }" class="navButton" routerLink="/main/">
                <div class="iconArea rippler" id="NetworkDashboard">
                    <div class="image icon-Icon_Dash"></div>
                </div>
                <div class="label">Network Dashboard</div>
            </div>

            <div
                (click)="handleClickEventNav($event, '/zdashboard/')"
                *ngIf="isZiti"
                [ngClass]="{ selected: url === '/zdashboard', disabled: disableMainNav }"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton"
            >
                <div class="iconArea rippler" id="NetworkDashboardV7">
                    <div class="image icon-Icon_Dash"></div>
                </div>
                <div class="label">Network Dashboard</div>
            </div>

            <div
                *ngIf="!isZiti"
                [ngClass]="{ selected: url === Appwans || url === Services }"
                [routerLink]="Services"
                class="navButton"
            >
                <div
                    *ngIf="authorizationService.canListAppWans || authorizationService.canListServices"
                    class="iconArea rippler"
                    id="ManageAppWANS"
                >
                    <div class="image icon-appwans"></div>
                </div>
                <div class="label">Services and AppWANs</div>
            </div>

            <div
                (click)="handleClickEventNav($event, Ziti_AppWans)"
                *ngIf="isZiti"
                [ngClass]="{ selected: url === Ziti_AppWans, disabled: disableMainNav }"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton ziti-appwans-nav"
            >
                <div class="iconArea rippler" id="ManageAppWANS">
                    <div class="image icon-appwans"></div>
                </div>
                <div class="label">AppWANs</div>
            </div>

            <div
                (click)="handleClickEventNav($event, Ziti_Services)"
                *ngIf="isZiti"
                [ngClass]="{ selected: url === Ziti_Services, disabled: disableMainNav }"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton ziti-services-nav"
            >
                <div class="iconArea rippler" id="ManageServices">
                    <div class="image icon-services"></div>
                </div>
                <div class="label">Services</div>
            </div>

            <div
                (click)="handleClickEventNav($event, Ziti_Endpoints)"
                *ngIf="isZiti"
                [ngClass]="{ selected: url === Ziti_Endpoints, disabled: disableMainNav }"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton ziti-endpoints-nav"
            >
                <div class="iconArea rippler" id="ManageZitiEndpoints">
                    <div class="image icon-Icon_CL"></div>
                </div>
                <div class="label">Endpoints</div>
            </div>
            <div
                (click)="handleClickEventNav($event, Edge_Routers)"
                *ngIf="isZiti"
                [ngClass]="{
                    selected:
                        url === Edge_Routers || url === '/v7/policies' || url === Ziti_Service_Edge_Router_Policies,
                    disabled: disableMainNav
                }"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton"
            >
                <div class="iconArea rippler" id="ManageRouters">
                    <div class="image icon-routers"></div>
                </div>
                <div class="label">Edge Routers</div>
            </div>
            <div
                (click)="handleClickEventNav($event, Posture_Checks)"
                *ngIf="isZiti && authorizationService.canListPostureChecks() && networkCanHavePostureChecks"
                [ngClass]="{ selected: url === Posture_Checks, disabled: disableMainNav }"
                class="navButton"
            >
                <div class="iconArea rippler" id="ManagePostureChecks">
                    <div class="image icon-posturechecks"></div>
                </div>
                <div class="label">Posture Checks</div>
            </div>

            <div
                *ngIf="
                    authorizationService.canListNetworks() &&
                    authorizationService.canReadAlarms() &&
                    !authorizationService.canReadElasticSearch()
                "
                [ngClass]="{ selected: url === Management_Events || url === Alarms }"
                [routerLink]="Alarms"
                class="navButton"
            >
                <div class="iconArea rippler" id="NetworkAlarms">
                    <div class="image icon-Icon_Events"></div>
                </div>
                <div class="label">Network Alarms</div>
            </div>

            <div
                *ngIf="!isZiti"
                [ngClass]="{
                    selected: url === Gateways || url === Clients || url === Azure_Virtual_Wan_Sites || url === Groups
                }"
                [routerLink]="Gateways"
                class="navButton"
            >
                <div
                    *ngIf="authorizationService.canListEndpoints || authorizationService.canListGroups"
                    class="iconArea rippler"
                    id="ManageEndpoints"
                >
                    <div class="image icon-endpoints"></div>
                </div>
                <div class="label">Endpoints</div>
            </div>
            <div
                *ngIf="azureEnabled"
                [ngClass]="{ selected: url === '/virtualwans' }"
                class="navButton"
                routerLink="/virtualwans/"
            >
                <div class="iconArea rippler" id="ManageAVWS">
                    <div class="image icon-virtualwans"></div>
                </div>
                <div class="label">Azure Virtual WANs</div>
            </div>
            <div
                (click)="handleClickEventNav($event, Ziti_Certificate_Authorities)"
                *ngIf="
                    (authorizationService.canListCAs() ||
                        authorizationService.canListAuthPolicies() ||
                        authorizationService.canListJwtSigners()) &&
                    isZiti
                "
                [ngClass]="{
                    selected: url === Ziti_Certificate_Authorities || url === Auth_Policies || url === JWT_Signers,
                    disabled: disableMainNav
                }"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton"
            >
                <div class="iconArea rippler" id="ManageCAs">
                    <div class="image icon-CAs"></div>
                </div>
                <div class="label">Authentication</div>
            </div>
            <div
                *ngIf="canManageSubscriptions() && !this.isSelfServiceTeamsTier"
                [ngClass]="{
                    selected:
                        url === Subscriptions_VWAN ||
                        url === Subscriptions_AAD ||
                        url === Subscriptions_AD ||
                        url === Subscriptions_VIRTUALWAN,
                    disabled: disableMainNav
                }"
                [routerLink]="Subscriptions_VWAN"
                class="navButton"
            >
                <div class="iconArea rippler" id="ManageSubscriptions">
                    <div class="image icon-integrations"></div>
                </div>
                <div class="label">Integrations</div>
            </div>

            <div
                *ngIf="
                    authorizationService.canListNetworks() &&
                    authorizationService.canReadElasticSearch() &&
                    apiService.getNetworkVersion(currentNetwork) > 6
                "
                [ngClass]="{ selected: url === Metrics, disabled: disableMainNav }"
                [routerLink]="Metrics"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton"
            >
                <div class="iconArea rippler" id="Metrics">
                    <div class="image icon-AnalyticsReports"></div>
                </div>
                <div class="label">Metrics</div>
            </div>

            <div
                *ngIf="authorizationService.canListNetworks() && authorizationService.canReadElasticSearch()"
                [ngClass]="{
                    selected: url === Management_Events || url === Alarms,
                    disabled: disableMainNav && !provisioning
                }"
                [routerLink]="Management_Events"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton"
            >
                <div class="iconArea rippler" id="NetworkEvents">
                    <div class="image icon-Icon_Events"></div>
                </div>
                <div class="label">Events</div>
            </div>

            <div
                (click)="handleClickEventNav($event, Certificate_Authorities)"
                *ngIf="authorizationService.canListCAs() && zitiEnabledService.zitiEnabled"
                [ngClass]="{ selected: url === Certificate_Authorities, disabled: disableMainNav }"
                [title]="disableMainNav ? disabledToolTip : ''"
                class="navButton"
            >
                <div class="iconArea rippler" id="ManageCAs">
                    <div class="image icon-CAs"></div>
                </div>
                <div class="label">Certificate Authorities</div>
            </div>

            <div
                *ngIf="networkCanSupportBrowZer && featureService.powerUserEnabled && authorizationService.canCreateNetworks()"
                [ngClass]="{ selected: url === '/v7/browzer' }"
                class="navButton"
                routerLink="/v7/browzer"
            >
                <div class="iconArea rippler" id="BrowZerApps">
                    <div class="image icon-BrowZer"></div>
                </div>
                <div class="label">BrowZer Apps</div>
            </div>

            <div
                [ngClass]="{
                    selected: url === '/networks' || url === '/network-groups' || url === '/networks-combined'
                }"
                class="navButton"
                routerLink="/networks-combined"
            >
                <div class="iconArea rippler" id="ManageNetworks">
                    <div class="image icon-ManageNetworks">
                        <span *ngIf="upgradeableNetworks" class="counterBadge">{{ upgradeableNetworks }}</span>
                    </div>
                </div>
                <div class="label">Manage Networks</div>
            </div>

            <div class="nav-details">
                <div
                    *ngIf="brandingModeService.logosAvailable"
                    [ngClass]="{ dark: brandingService.darkModeEnabled }"
                    class="navlogo"
                ></div>
                <div *ngIf="currentNetwork.id" class="versionInfo">
                    <div class="networkInfo" id="NetworkVersion">
                        Network Version: {{ majorVersionNumber }}.{{ minorVersionNumber }}.{{ patchVersionNumber }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{ open: area === 'Organization' }" class="navArea" id="OrganizationNavigation">
        <div [ngClass]="{ logoEnabled: brandingModeService.logosAvailable }" class="navList">
            <div
                *ngIf="
                    !billingStatusInvalid &&
                    (authorizationService.canListUserIdentities() || authorizationService.canListInvitations())
                "
                [ngClass]="{ selected: url === Users || url === Manage_Invitations }"
                [routerLink]="Users"
                class="navButton"
            >
                <div class="iconArea rippler" id="ManageUsers">
                    <div class="image icon-ManageUsers"></div>
                </div>
                <div class="label">Users</div>
            </div>
            <div
                *ngIf="!billingStatusInvalid && authorizationService.canListApiAccountIdentities()"
                [ngClass]="{ selected: url === Tokens }"
                [routerLink]="Tokens"
                class="navButton"
            >
                <div class="iconArea rippler" id="ManageAPIAccounts">
                    <div class="image icon-ManageAPIAccounts"></div>
                </div>
                <div class="label">API Accounts</div>
            </div>
            <div
            *ngIf="authorizationService.canListOrganizations()"
            [ngClass]="{ selected: url === Stored_Secrets }"
            [routerLink]="Stored_Secrets"
            class="navButton"
        >
            <div class="iconArea rippler" id="StoredSecrets">
                <div class="image icon-posturechecks"></div>
            </div>
            <div class="label">Stored Secrets</div>
        </div>
            <div
                *ngIf="!billingStatusInvalid && featureService.experimentalFeatures"
                [ngClass]="{ selected: url === '/roles' }"
                class="navButton"
                routerLink="/roles/"
            >
                <div class="iconArea rippler" id="ManageRoles">
                    <div class="image icon-Manage-Roles"></div>
                </div>
                <div class="label">Roles & Permissions</div>
            </div>
            <!-- TODO updating billing auth -->

            <div
                *ngIf="authorizationService.canViewPaymentProfile() && selfServiceBilling"
                [ngClass]="{ selected: url === Billing }"
                [routerLink]="Billing"
                class="navButton"
            >
                <div class="iconArea rippler" id="BillingDashboard">
                    <div class="image icon-ManageBilling"></div>
                </div>
                <div class="label">Billing Dashboard</div>
            </div>

            <div
                *ngIf="!billingStatusInvalid && authorizationService.canReadElasticSearch(currentOrg.getId())"
                [ngClass]="{
                    selected:
                        url === Resource_Usage ||
                        url === Daily_Stats ||
                        url === Network_Group_Summary ||
                        url === 'daily-endpoint-utilization'
                }"
                [routerLink]="Network_Group_Summary"
                class="navButton"
            >
                <div class="iconArea rippler" id="ReportingAnalytics">
                    <div class="image icon-AnalyticsReports"></div>
                </div>
                <div class="label">Reporting And Analytics</div>
            </div>
            <div
                *ngIf="authorizationService.canViewPaymentProfile() && enterpriseBilling"
                [ngClass]="{
                    selected:
                        url === Payment_Profile ||
                        url === Account_Details ||
                        url === Billing_Subscriptions ||
                        url === Billing_Subscription_Overages ||
                        url === Billing_Subscription_Usage
                }"
                [routerLink]="Payment_Profile"
                class="navButton"
            >
                <div class="iconArea rippler" id="BillingManagement">
                    <div class="image icon-ManageBilling"></div>
                </div>
                <div class="label">Billing Management</div>
            </div>
            <div
                *ngIf="
                    !billingStatusInvalid &&
                    ((currentTenant && authorizationService.canUpdateTenant(currentTenant.id)) ||
                        authorizationService.canUpdateColors() ||
                        authorizationService.canDeleteColors() ||
                        authorizationService.canUpdateLogos() ||
                        authorizationService.canDeleteLogos())
                "
                [ngClass]="{ selected: url === Settings }"
                [routerLink]="Settings"
                class="navButton"
            >
                <div class="iconArea rippler" id="OrganizationalSettings">
                    <div class="image icon-OrgSettings"></div>
                </div>
                <div class="label">Organizational Settings</div>
            </div>
            <div
                *ngIf="
                    (!billingEnabledService.billingEnabled || tenants.length > 1) &&
                    authorizationService.canListTenants() &&
                    (!this.isSelfService || authorizationService.isSupportUser)
                "
                [ngClass]="{ selected: url === Organizations }"
                [routerLink]="Organizations"
                class="navButton"
            >
                <div class="iconArea rippler" id="ManageOrganizations">
                    <div class="image icon-ManageOrg"></div>
                </div>
                <div class="label">Manage Organizations</div>
            </div>
            <div
            *ngIf="
                (!billingEnabledService.billingEnabled || tenants.length > 1) &&
                authorizationService.canListTenants() &&
                (!this.isSelfService || authorizationService.isSupportUser)
            "
            [ngClass]="{ selected: url === Oidc_Audiences }"
            [routerLink]="Oidc_Audiences"
            class="navButton"
        >
            <div class="iconArea rippler" id="ManageOidcAudiences">
                <div class="image icon-ManageOidcAudience"></div>
            </div>
            <div class="label">Manage Oidc Audiences</div>
        </div>
        </div>
        <div
            *ngIf="brandingModeService.logosAvailable"
            [ngClass]="{ dark: brandingService.darkModeEnabled }"
            class="navlogo"
        ></div>
    </div>
    <div [ngClass]="{ open: area === 'Super' }" class="navArea" id="SuperNavigation">
        <div [ngClass]="{ logoEnabled: brandingModeService.logosAvailable }" class="navList">
            <div [ngClass]="{ selected: url === User }" [routerLink]="User" class="navButton">
                <div class="iconArea rippler" id="ManageUser">
                    <div class="image icon-ManageUsers"></div>
                </div>
                <div class="label">Manage Users</div>
            </div>
        </div>
        <div
            *ngIf="brandingModeService.logosAvailable"
            [ngClass]="{ dark: brandingService.darkModeEnabled }"
            class="navlogo"
        ></div>
        <div class="versionInfo">
            <div id="BuildTime">{{ version?.buildTime }}</div>
        </div>
    </div>
    <div [ngClass]="{ open: area === 'Support' }" class="navArea" id="SupportNavigation">
        <div [ngClass]="{ logoEnabled: brandingModeService.logosAvailable }" class="navList">
            <div (click)="openHub()" class="navButton">
                <div class="iconArea rippler" id="SupportHub">
                    <div class="image icon-hub"></div>
                </div>
                <div class="label">Support Hub</div>
            </div>
            <div (click)="openTicket()" *ngIf="isSelfServiceTeamsTier === false" class="navButton">
                <div class="iconArea rippler" id="SubmitTicket">
                    <div class="image icon-Ticket"></div>
                </div>
                <div class="label">Submit A Ticket</div>
            </div>

            <div (click)="gotoCommunity()" class="navButton">
                <div class="iconArea rippler">
                    <div class="image icon-community"></div>
                </div>
                <div class="label">Community Support</div>
            </div>

            <div (click)="openSupport()" class="navButton">
                <div class="iconArea rippler" id="Support">
                    <div class="image icon-InlineHelp"></div>
                </div>
                <div class="label">Inline Help</div>
            </div>
            <div (click)="openTour()" *ngIf="!billingStatusInvalid" class="navButton">
                <div class="iconArea rippler" id="ConsoleTour">
                    <div class="image icon-tour"></div>
                </div>
                <div class="label">Console Tour</div>
            </div>
            <div (click)="openPageTour()" *ngIf="!isZiti" class="navButton">
                <div class="iconArea rippler" id="PageTour">
                    <div class="image icon-PageTour"></div>
                </div>
                <div class="label">Page Tour</div>
            </div>
            <div (click)="gotoReleaseNotes()" *ngIf="!billingStatusInvalid" class="navButton">
                <div class="iconArea rippler">
                    <div class="image icon-WhatsNew"></div>
                </div>
                <div class="label">What's New</div>
            </div>
            <div
                *ngIf="!billingStatusInvalid && showGettingStarted"
                [ngClass]="{ selected: url === Getting_Started }"
                [routerLink]="Getting_Started"
                class="navButton getting-started"
            >
                <div class="iconArea rippler">
                    <div class="image icon-QuickStart"></div>
                </div>
                <div class="label">Getting Started</div>
            </div>
        </div>
        <div
            *ngIf="brandingModeService.logosAvailable"
            [ngClass]="{ dark: brandingService.darkModeEnabled }"
            class="navlogo"
        ></div>
        <div class="versionInfo">
            <div id="BuildTime">{{ version?.buildTime }}</div>
        </div>
    </div>
    <div [ngClass]="{ open: area === 'Search' }" class="navArea" id="SearchNavigation">Search</div>
</nav>
