import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZitiDashboardComponent } from '@netfoundry-ui/page/ziti-dashboard';
import { EdgeRouterPoliciesComponent } from '@netfoundry-ui/feature/ziti/edge-router-policies';
import { AzureVirtualWansComponent } from '@netfoundry-ui/page/azurevirtualwans';
import { UserSettingsComponent } from '@netfoundry-ui/page/user-settings';
import { CallbackComponent } from '@netfoundry-ui/page/callback';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CertificateAuthoritiesComponent } from '@netfoundry-ui/page/certificate-authorities';
import { DashboardComponent } from '@netfoundry-ui/page/dashboard';
import { InvitationComponent } from '@netfoundry-ui/page/invitation';
import { LoginComponent } from '@netfoundry-ui/page/login';
import { LogsComponent } from '@netfoundry-ui/page/logs';
import { MetricsComponent } from '@netfoundry-ui/page/metrics';
import { NetflowCollectorsComponent } from '@netfoundry-ui/page/netflow-collectors';
import { NetworkGroupsComponent } from '@netfoundry-ui/page/network-groups';
import { NetworksCombinedComponent } from '@netfoundry-ui/page/networks-combined';
import { NetworksComponent } from '@netfoundry-ui/page/networks';
import { RolesComponent } from '@netfoundry-ui/page/roles';
import { RedirectGuard, SelfserviceComponent } from '@netfoundry-ui/page/selfservice';
import { PrivateKeyUploadFormComponent } from '@netfoundry-ui/feature/form/private-key-upload-form';
import { TestpickboxComponent } from '@netfoundry-ui/page/test-pick-box';
import { UserinfoComponent } from '@netfoundry-ui/page/userinfo';
import { AuthenticationGuard, AuthorizationResolveService } from '@netfoundry-ui/shared/authorization';
import { GrowlerErrorHistoryComponent } from '@netfoundry-ui/shared/growler';
import { GettingStartedComponent, SampleServiceComponent } from '@netfoundry-ui/feature/getting-started';
import { SignupCompleteComponent, SignupReturnComponent } from '@netfoundry-ui/feature/views/signup';
import { AuthPoliciesComponent, PageAuthPoliciesModule } from '@netfoundry-ui/page/auth-policies';
import { JwtSignersComponent } from '@netfoundry-ui/page/jwt-signers';
import { OidcAudiencesComponent } from '@netfoundry-ui/page/oidc-audiences';
import { environment } from '../environments/environment';

const defaultRoutes: Routes = [
    {
        path: 'endpoints',
        loadChildren: () => import('@netfoundry-ui/page/endpoints').then((m) => m.PageEndpointsModule),
    },
    { path: 'gateways', redirectTo: '/endpoints/gateways', pathMatch: 'full' },
    { path: 'app-gateways', redirectTo: '/endpoints/gateways', pathMatch: 'full' },

    { path: 'clients', redirectTo: '/endpoints/clients', pathMatch: 'full' },
    { path: 'app-clients', redirectTo: '/endpoints/clients', pathMatch: 'full' },

    { path: 'groups', redirectTo: '/endpoints/groups', pathMatch: 'full' },
    { path: 'app-groups', redirectTo: '/endpoints/groups', pathMatch: 'full' },

    { path: 'azure-virtual-wan-sites', redirectTo: '/endpoints/azure-virtual-wan-sites', pathMatch: 'full' },

    {
        path: 'connections',
        loadChildren: () => import('@netfoundry-ui/page/connections').then((m) => m.PageConnectionsModule),
    },
    { path: 'appwans', redirectTo: '/connections/appwans', pathMatch: 'full' },
    { path: 'app-appwans', redirectTo: '/connections/appwans', pathMatch: 'full' },
    { path: 'services', redirectTo: '/connections/services', pathMatch: 'full' },
    { path: 'app-services', redirectTo: '/connections/services', pathMatch: 'full' },

    {
        path: 'virtualwans',
        component: AzureVirtualWansComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-virtualwans', redirectTo: '/virtualwans', pathMatch: 'full' },

    {
        path: 'integrations',
        loadChildren: () => import('@netfoundry-ui/page/subscriptions').then((m) => m.PageSubscriptionsModule),
    },
    { path: 'app-subscriptions', redirectTo: '/integrations/endpoint-lifecycle', pathMatch: 'full' },
    { path: 'virtual-wan', redirectTo: '/integrations/virtual-wan', pathMatch: 'full' },
    { path: 'client-synchronization', redirectTo: '/integrations/aad', pathMatch: 'full' },

    {
        path: '',
        redirectTo: '/v7/dashboard',
        pathMatch: 'full',
    },
    {
        path: 'main',
        component: DashboardComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-main', redirectTo: '/main', pathMatch: 'full' },

    { path: 'login', component: LoginComponent },
    {
        path: 'stored-secrets/:id',
        component: PrivateKeyUploadFormComponent,
    },
    { path: 'app-login', redirectTo: '/login', pathMatch: 'full' },
    { path: 'vanity-logout', redirectTo: '/login?vanityLogout=true', pathMatch: 'full' },

    {
        path: 'identity',
        loadChildren: () => import('@netfoundry-ui/page/identities').then((m) => m.PageIdentitiesModule),
    },

    {
        path: 'user-identities',
        loadChildren: () => import('@netfoundry-ui/page/identities').then((m) => m.PageIdentitiesModule),
    },
    { path: 'users', redirectTo: '/identities', pathMatch: 'full' },
    { path: 'user', redirectTo: '/identity', pathMatch: 'full' },
    { path: 'user-roles', redirectTo: '/user-identities/user-roles', pathMatch: 'full' },
    { path: 'app-users', redirectTo: '/user-identities', pathMatch: 'full' },
    { path: 'tokens', redirectTo: '/user-identities/tokens', pathMatch: 'full' },
    { path: 'manage-invitations', redirectTo: '/user-identities/invitations', pathMatch: 'full' },

    {
        path: 'reporting',
        loadChildren: () => import('@netfoundry-ui/page/reporting').then((m) => m.PageReportingModule),
    },
    { path: 'dailystats', redirectTo: '/reporting', pathMatch: 'full' },
    { path: 'network-group-summary', redirectTo: '/reporting', pathMatch: 'full' },
    { path: 'daily-endpoint-utilization', redirectTo: '/reporting', pathMatch: 'full' },

    {
        path: 'profile',
        component: UserinfoComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-profile', redirectTo: '/profile', pathMatch: 'full' },

    {
        path: 'user-settings',
        component: UserSettingsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-user-settings', redirectTo: '/user-settings', pathMatch: 'full' },

    {
        path: 'logs',
        component: LogsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'app-logs', redirectTo: '/logs', pathMatch: 'full' },

    {
        path: 'management-events',
        loadChildren: () => import('@netfoundry-ui/page/events').then((m) => m.PageEventsModule),
    },
    {
        path: 'network-controllers',
        loadChildren: () =>
            import('@netfoundry-ui/page/network-controllers').then((m) => m.PageNetworkControllersModule),
    },
    {
        path: 'allocated-ips',
        loadChildren: () => import('@netfoundry-ui/page/allocated-ips').then((m) => m.PageAllocatedIpsModule),
    },
    {
        path: 'stored-secrets',
        loadChildren: () => import('@netfoundry-ui/page/stored-secrets').then((m) => m.PageStoredSecretsModule),
    },

    {
        path: 'network-events',
        loadChildren: () =>
            import('@netfoundry-ui/page/network-events-metrics-tab').then((m) => m.PageNetworkEventsMetricsTabModule),
    },
    { path: 'app-events', redirectTo: '/events', pathMatch: 'full' },

    {
        path: 'alarms',
        loadChildren: () => import('@netfoundry-ui/page/alarms').then((m) => m.PageAlarmsModule),
    },
    { path: 'app-alarms', redirectTo: '/alarms', pathMatch: 'full' },

    { path: 'callback', component: CallbackComponent },

    { path: 'invitation', component: InvitationComponent },
    { path: 'app-invitation', redirectTo: '/invitation', pathMatch: 'full' },

    {
        path: 'certificate-authorities',
        component: CertificateAuthoritiesComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },

    {
        path: 'auth-policies',
        component: AuthPoliciesComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'jwt-signers',
        component: JwtSignersComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },

    { path: 'signup',component: SelfserviceComponent, canActivate: [RedirectGuard] },
    { path: 'trial', component: SelfserviceComponent },
    { path: 'selfservice', component: SelfserviceComponent },
    {
        path: 'error-history',
        component: GrowlerErrorHistoryComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'page-not-found',
        loadChildren: () => import('@netfoundry-ui/page/page-not-found').then((m) => m.PagePageNotFoundModule),
    },
    {
        path: 'ziti-dashboard',
        loadChildren: () => import('ziti-console-lib').then((m) => m.OpenZitiConsoleLibModule),
    },
    {
        path: 'billing',
        loadChildren: () => import('@netfoundry-ui/page/billing').then((m) => m.PageBillingModule),
    },
    {
        path: 'payment-profile',
        loadChildren: () => import('@netfoundry-ui/page/payment-profile').then((m) => m.PagePaymentProfileModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'account-details',
        loadChildren: () => import('@netfoundry-ui/page/account-details').then((m) => m.PageAccountDetailsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing-subscriptions',
        loadChildren: () =>
            import('@netfoundry-ui/page/billing-subscriptions').then((m) => m.PageBillingSubscriptionsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing-subscription-overages',
        loadChildren: () =>
            import('@netfoundry-ui/page/billing-subscription-overages').then(
                (m) => m.PageBillingSubscriptionOveragesModule
            ),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'billing-subscription-usage',
        loadChildren: () =>
            import('@netfoundry-ui/page/billing-subscription-usage').then((m) => m.PageBillingSubscriptionUsageModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'organizations',
        loadChildren: () => import('@netfoundry-ui/page/organizations').then((m) => m.PageOrganizationsModule),
    },
    { path: 'settings', redirectTo: '/organizations/settings', pathMatch: 'full' },
    { path: 'app-settings', redirectTo: '/organizations/settings', pathMatch: 'full' },
    {
        path: 'oidc-audiences',
        component: OidcAudiencesComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'roles',
        component: RolesComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'collectors',
        component: NetflowCollectorsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'notifications',
        loadChildren: () => import('@netfoundry-ui/page/notifications').then((m) => m.PageNotificationsModule),
    },
    {
        path: 'network-groups',
        component: NetworkGroupsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'process-executions',
        loadChildren: () => import('@netfoundry-ui/page/process-executions').then((m) => m.PageProcessExecutionsModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'attributes-explorer',
        loadChildren: () =>
            import('@netfoundry-ui/page/attributes-explorer').then((m) => m.PageAttributesExplorerModule),
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },

    // Ziti/V7 Routes
    {
        path: 'v7/connections',
        loadChildren: () => import('@netfoundry-ui/page/ziti-connections').then((m) => m.PageZitiConnectionsModule),
    },
    { path: 'zappwans', redirectTo: 'v7/connections/appwans', pathMatch: 'full' },
    { path: 'v7/appwans', redirectTo: 'v7/connections/appwans', pathMatch: 'full' },
    { path: 'v7/services', redirectTo: 'v7/connections/services', pathMatch: 'full' },
    { path: 'zservices', redirectTo: 'v7/connections/services', pathMatch: 'full' },
    { path: 'configs', redirectTo: 'v7/connections/configs', pathMatch: 'full' },

    {
        path: 'v7/endpoints',
        loadChildren: () => import('@netfoundry-ui/page/ziti-endpoints').then((m) => m.PageZitiEndpointsModule),
    },
    { path: 'zendpoints', redirectTo: 'v7/endpoints', pathMatch: 'full' },

    {
        path: 'v7/routers',
        loadChildren: () => import('@netfoundry-ui/page/ziti-edge-routers').then((m) => m.PageZitiEdgeRoutersModule),
    },
    { path: 'zrouters', redirectTo: 'v7/routers', pathMatch: 'full' },

    {
        path: 'v7/policies',
        component: EdgeRouterPoliciesComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'v7/service-edge-router-policies',
        loadChildren: () =>
            import('@netfoundry-ui/page/ziti-service-edge-router-policies').then(
                (m) => m.PageZitiServiceEdgeRouterPolicies
            ),
    },
    { path: 'zpolicies', redirectTo: 'v7/policies', pathMatch: 'full' },

    {
        path: 'v7/dashboard',
        component: ZitiDashboardComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'zdashboard', redirectTo: 'v7/dashboard', pathMatch: 'full' },

    {
        path: 'v7/certificate-authorities',
        loadChildren: () =>
            import('@netfoundry-ui/page/ziti-certificate-authorities').then(
                (m) => m.PageZitiCertificateAuthoritiesModule
            ),
    },
    {
        path: 'v7/browzer',
        loadChildren: () => import('@netfoundry-ui/page/browzer').then((m) => m.PageBrowzerModule),
    },
    {
        path: 'browzer-bootstrappers',
        loadChildren: () => import('@netfoundry-ui/page/browzer-bootstrappers').then((m) => m.PageBrowzerBootstrappersModule),
    },
    {
        path: 'networks6',
        component: NetworksComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'networks',
        component: NetworksCombinedComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'networks-combined', redirectTo: 'networks', pathMatch: 'full' },
    {
        path: 'posture-checks',
        loadChildren: () => import('@netfoundry-ui/page/posture-checks').then((m) => m.PagePostureChecksModule),
    },

    {
        path: 'v7/topology',
        loadChildren: () => import('@netfoundry-ui/page/topology').then((m) => m.PageTopologyModule),
    },
    { path: 'topology', redirectTo: 'v7/topology', pathMatch: 'full' },
    {
        path: 'v7/latency',
        loadChildren: () => import('@netfoundry-ui/page/fabric-latency').then((m) => m.PageFabricLatencyModule),
    },
    { path: 'fabric-latency', redirectTo: 'v7/latency', pathMatch: 'full' },
    {
        path: 'v7/metrics',
        component: MetricsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'v7/metrics',
        component: MetricsComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    { path: 'metrics', redirectTo: 'v7/metrics', pathMatch: 'full' },
    {
        path: 'getting-started',
        component: GettingStartedComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'getting-started/sample-service',
        component: SampleServiceComponent,
        canActivate: [AuthenticationGuard],
        resolve: { authorization: AuthorizationResolveService },
    },
    {
        path: 'signup-complete',
        component: SignupCompleteComponent,
    },
    {
        path: 'signup-return',
        component: SignupReturnComponent,
    },
    // NOTE: any new route must be added before this route page not found route
    { path: '**', redirectTo: 'page-not-found', resolve: { authorization: AuthorizationResolveService } },
];
//if lower only, add to the array, don't define separate array

export const routes: Routes = defaultRoutes;

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    providers: [AuthenticationGuard, AuthorizationResolveService],
})
export class AppRoutingModule {}
