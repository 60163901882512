import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SortOrder } from '@lagoshny/ngx-hateoas-client';
import { NetworkUpgradeService } from '@netfoundry-ui/feature-network-upgrade';
import { DownloadEntitiesComponent } from '@netfoundry-ui/feature/download-entities';
import { ClientMfaFormComponent } from '@netfoundry-ui/feature/form/client-mfa-form';
import { NetworkFormComponent } from '@netfoundry-ui/feature/form/network-form';
import { V2NetworkCreationFormComponent } from '@netfoundry-ui/feature/form/v2-network-creation-form';
import { JsonViewComponent } from '@netfoundry-ui/feature/json-view';
import { NetworkInfoComponent } from '@netfoundry-ui/feature/network-info';
import { NetworkInfoV2Component } from '@netfoundry-ui/feature/network-info-v2';
import { O365BreakoutComponent } from '@netfoundry-ui/feature/o365-breakout';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Environment, ENVIRONMENT, Network, NetworkGroup, NetworkV2 } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    HTTP_CLIENT,
    LoggerService,
    NetworkGroupService,
    NetworkService,
    RefresherService,
    TokenService,
} from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import { SortbyPipe } from '@netfoundry-ui/ui/pipes';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { FileSaverService } from 'ngx-filesaver';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

const deletingStatus = 800;
const buildingStatus = 200;
const completeStatus = 300;

const networkGroupNameString = 'Network Group';

const columns = [
    'name',
    'status',
    'networkGroupName',
    'productFamily',
    'productVersion',
    'o365BreakoutCategory',
    'createdAt',
    'provisionedAt',
    'updatedAt',
];
const filename = 'networks';
const translateStatus = true;

@Component({
    selector: 'app-networks',
    templateUrl: './networks.component.html',
    styleUrls: ['./networks.component.scss'],
})
export class NetworksComponent implements OnInit, OnDestroy {
    typeName = 'V6 Network';
    items = [];
    displayedItems = [];
    // itemCount = 0;
    showList = false;
    isLoading = true;
    allToggled = false;
    openIndex = -1;
    sorting = 'name';
    ordering: SortOrder = 'ASC';
    filterString = '';
    public selectedNetworkIds = [];
    public hasSomeDeletePermission = true;
    canListNetworkGroups = false;
    dialogRef;
    provisionedAt;
    isDeleteAction = false;
    currentOrg = new NetworkGroup({});
    page = 1;
    pageSize = this.environment.pageSize;
    networkGroupMap = {};
    networkGroupFilter = false;
    @Output() networkChange: EventEmitter<string> = new EventEmitter();
    selectedNetworkGroupName = 'Network Group';
    networkGroups = [];
    filteringBy = '';
    // totalElements = 0;
    currentNetwork: Network | NetworkV2;
    networksV6 = [];
    networksV7 = [];
    upgradeableVersions: any[] = [];
    private deleting = 0;
    private subscription = new Subscription();
    private setNetworkAfterDelete = false;
    private deletedNetworkIds = [];
    private inDeletingStatusCount = 0;
    private noDeleteAuthCount = 0;

    constructor(
        private logger: LoggerService,
        private networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private cookieService: CookieService,
        private apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute,
        public dialogForm: MatDialog,
        private refresher: RefresherService,
        public authorizationService: AuthorizationService,
        public filterService: TableFilterService,
        private networkGroupService: NetworkGroupService,
        private sortByPipe: SortbyPipe,
        private csvDownloadService: CsvDownloadService,
        private fileSaverService: FileSaverService,
        private tokenService: TokenService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        public featureService: FeatureService,
        public growlerService: GrowlerService,
        private networkUpgradeService: NetworkUpgradeService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.canListNetworkGroups = this.authorizationService.canListOrganizations();
    }

    async ngOnInit() {
        // subscribing to the filter service to handle the change in page
        this.subscription.add(
            this.filterService.setPageEvent.subscribe((pageNum) => {
                this.page = pageNum;
            })
        );

        // subscribing to the filter service to handle search filter changes
        this.subscription.add(
            this.filterService.setFilterEvent.subscribe((filterString) => {
                this.filterString = filterString;
                this.applyNetworkGroupFilter(this.networkGroupFilter);
            })
        );

        // network groups - create lookup table for names
        if (this.canListNetworkGroups) {
            await this.networkGroupService
                .get()
                .pipe(take(1))
                .toPromise()
                .then((result) => {
                    for (const item of result) {
                        const networkGroup = new NetworkGroup(item);
                        this.networkGroups.push(networkGroup);
                        this.networkGroupMap[networkGroup.id] = networkGroup.name;
                    }
                });

            this.networkGroups = this.sortByPipe.transform(this.networkGroups, 'name', 'asc');
            // subscribing to the network group id
            this.subscription.add(
                this.route.queryParams.subscribe((queryParams) => {
                    const networkGroupId = queryParams['networkGroupId'];

                    if (networkGroupId != null) {
                        this.setNetworkGroupNameFilter(networkGroupId);
                    }
                })
            );
        }

        // call networks after we determine the networkGroups (org)
        this.subscription.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = new NetworkGroup(org);
                if (this.authorizationService.canListNetworks()) {
                    this.getAllNetworks();
                } else {
                    this.isLoading = false;
                    this.showList = false;
                }
            })
        );

        this.apiService.currentNetwork.subscribe((network) => {
            this.currentNetwork = network;
        });
    }

    public async getAllNetworks() {
        this.isLoading = true;
        this.showList = false;

        const proms = [];
        proms.push(this._loadV6networks());

        await Promise.all(proms).then(() => {
            this.logger.info('All network promises returned');
        });

        this.logger.info('V6 Networks', this.networksV6);

        // combine the list
        let combined = [];
        combined = combined.concat(this.networksV6);

        // process list
        const processed = [];
        for (const network of combined) {
            processed.push(this._process_model(network));
        }

        this.deletedNetworkIds = [];
        this.items = processed;
        this.displayedItems = this.items;

        this.showList = this.items.length > 0;
        this.isLoading = false;
        this.isDeleteAction = this.anyToggled();

        // this.totalElements = this.items.length;
        this.filterService.setTotalElements(this.items.length);

        // TODO set total pages based on this.networkService.page.totalPages once using backend pagination
        this.filterService.updateTotalPages();

        this.applyNetworkGroupFilter(this.networkGroupFilter);

        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval * 4);
    }

    applyFilter(filteredList) {
        this.displayedItems = this.filterService.applyLocalFilter(filteredList, this.buildNetworkSearchString);
        const newSelectedNetworkIds = [];
        for (const network of this.displayedItems) {
            if ((this.selectedNetworkIds.indexOf(network.id) > -1 || this.allToggled) && network.status <= 600) {
                newSelectedNetworkIds.push(network.id);
                network.selected = true;
            } else {
                network.selected = false;
            }
        }
        this.selectedNetworkIds = newSelectedNetworkIds;
        this.isDeleteAction = this.anyToggled();
    }

    ngOnDestroy() {
        this.refresher.disableRefresh();
        this.subscription.unsubscribe();
        // this.networkSubscription.unsubscribe();
        this.filterService.reset();
    }

    refresh() {
        this.refresher.disableRefresh();
        if (this.authorizationService.canListNetworks()) {
            // this.networkService.get();
            this.getAllNetworks();
        }
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval * 4);
    }

    toggleAll() {
        this.allToggled = !this.allToggled;
        this.selectedNetworkIds = [];

        for (const item of this.displayedItems) {
            // if (item.status < 700 && this.authorizationService.canDeleteNetwork(item.id, item.organizationId)) {
            if (this.authorizationService.canDeleteNetwork(item.id, item.organizationId)) {
                item.selected = this.allToggled;
                if (this.allToggled) {
                    this.selectedNetworkIds.push(item.id);
                }
            }
        }

        this.isDeleteAction = this.anyToggled();
    }

    copy(item: Network) {
        navigator.clipboard.writeText(item.id);
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Network Id Copied',
                'The networkId has been copied to your clipboard'
            )
        );
    }

    open(network: Network) {
        if (this.apiService.getNetworkVersion(network) < 7) {
            this.dialogRef = this.dialogForm.open(NetworkInfoComponent, {
                data: { network: network },
                height: '500px',
                width: '600px',
                autoFocus: false,
            });
        } else {
            this.dialogRef = this.dialogForm.open(NetworkInfoV2Component, {
                data: { network: network },
                height: '500px',
                width: '600px',
                autoFocus: false,
            });
        }
    }

    openNotification(network: Network) {
        this.router.navigate(['/notifications']);
    }

    openRename(network: Network) {
        const version = this.apiService.getNetworkVersion(network);
        if (version < 7) {
            this.dialogRef = this.dialogForm.open(NetworkFormComponent, {
                data: network,
                height: '250px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                if (result === undefined) {
                    this.refresh();
                }
            });
        } else {
            // TODO add edit form here
            this.dialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
                data: network,
                height: '250px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                if (result === undefined) {
                    this.refresh();
                }
            });
        }
    }

    async getDeletingTotal() {
        let total = 0;
        const names = [];

        const deleteString = 'Are you sure you would like to delete the following Network:';
        let afterListString = 'Doing so will delete all resources on this network';
        const appwanDeleteString = ' including the following ';
        const appwanList = [];

        for (const network of this.displayedItems) {
            if (network.selected) {
                names.push(network);
                total++;

                if (this.apiService.getNetworkVersion(network) < 7) {
                    await this.networkService
                        .getLinkedResources(network, 'appWans')
                        .toPromise()
                        .then((result) => {
                            result = this.sortByPipe.transform(result, 'name', 'asc');
                            for (const appwan of result) {
                                appwanList.push(appwan);
                            }
                            if (appwanList.length > 1) {
                                afterListString += `${appwanDeleteString} AppWans: `;
                            } else if (appwanList.length === 1) {
                                afterListString += `${appwanDeleteString} AppWan: `;
                            } else {
                                afterListString += '.';
                            }
                        });
                    break;
                }
            }
        }

        return {
            deleteString: deleteString,
            afterListString: afterListString,
            names: names,
            appwanList: appwanList,
        };
    }

    toggle(item: Network | NetworkV2) {
        const version = this.apiService.getNetworkVersion(item);
        let model = this.apiService.getNetworkModel(item);
        let orgId;

        if (version < 7) {
            orgId = model.getLinkedResourceId('organization');
        } else {
            model = model as NetworkV2;
            orgId = model.networkGroupId;
        }

        if (this.authorizationService.canDeleteNetwork(model.id, orgId)) {
            // use 'item' instead of 'model' so that it propagates to the view
            item.selected = !item.selected;

            if (this.allToggled) {
                this.allToggled = !this.allToggled;
            }

            const index = this.selectedNetworkIds.indexOf(model.id);
            if (index > -1) {
                this.selectedNetworkIds.splice(index, 1);
            } else {
                this.selectedNetworkIds.push(model.id);
            }

            this.isDeleteAction = this.anyToggled();
        } else {
            this.logger.info('Not authorized to delete network');
        }
    }

    anyToggled() {
        if (this.selectedNetworkIds.length > 0) {
            if (
                this.selectedNetworkIds.length ===
                this.items.length - this.inDeletingStatusCount - this.noDeleteAuthCount
            ) {
                this.allToggled = true;
            }
            return true;
        }
        this.allToggled = false;
        return false;
    }

    toggleMenu(event, index) {
        event.stopPropagation();
        if (index !== this.openIndex) {
            this.openIndex = index;
        } else {
            this.openIndex = -1;
        }
    }

    async create(addEvent) {
        // this.dialogRef = this.dialogForm.open(WizardComponent, { data: {}, minHeight: '100%', minWidth: '100%', height: '100%', width: '100%' });
        this.dialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
            data: {},
            id: 'V2NetworkDialog',
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });

        // refresh networks
        this.dialogRef.afterClosed().subscribe((data) => {
            this.getAllNetworks();
        });
    }

    async openConfirm(deleteEvent) {
        if (deleteEvent) {
            const deletingInfo = await this.getDeletingTotal();

            const data = {
                title: 'Delete',
                appendId: 'Networks',
                subtitle: deletingInfo.deleteString,
                afterListText: deletingInfo.afterListString,
                bulletList: deletingInfo.names,
                secondBulletList: deletingInfo.appwanList,
                icon: 'DestructiveDelete',
                action: 'Yes',
                isDestructive: true,
                itemName: deletingInfo.names[0].name,
            };
            this.dialogRef = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '440px',
                width: '600px',
                autoFocus: false,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
                // if the result has a property loggingOut, rather than being just a boolean value, the user is being
                //  logged out of the console and we should close the dialog without continuing
                if (result === undefined) {
                    this.confirmed(false);
                } else if (result['loggingOut'] === undefined) {
                    this.confirmed(result);
                }
            });
        }
    }

    confirmDelete(item: Network) {
        this.toggleAll();
        if (this.allToggled) {
            this.toggleAll();
        }

        item.selected = true;

        const index = this.selectedNetworkIds.indexOf(item.id);
        if (index === -1) {
            this.selectedNetworkIds.push(item.id);
        }
        this.isDeleteAction = true;
        this.openConfirm(true);
    }

    confirmed(event) {
        if (event) {
            this.refresher.disableRefresh();
            this.isLoading = true;
            this.deleting = this.getTotalSelected();

            this.logger.log('Deleting this many networks', this.deleting);

            for (let i = 0; i < this.displayedItems.length; i++) {
                if (this.displayedItems[i].selected) {
                    this.logger.log('Going to delete network', this.displayedItems[i]);

                    const version = this.apiService.getNetworkVersion(this.displayedItems[i]);

                    if (version < 7) {
                        // V6 Network
                        this.subscription.add(
                            this.networkService.delete(this.displayedItems[i]).subscribe(
                                (data) => {
                                    this.logger.log('Delete for v6 request sent', data);

                                    // forcing the deleted items to become untoggled
                                    this.toggle(this.displayedItems[i]);

                                    // if the delete was successful, add the id of the current client to the list of clients to be in deleting state
                                    this.deletedNetworkIds.push(this.displayedItems[i].id);
                                    this.deleting--;

                                    // checking if the current item that is being deleted is set to the current network
                                    if (this.currentNetwork.id === this.displayedItems[i].id) {
                                        // if it is, flagging setNetworkAfterDelete as true so that the current network may be updated during the refresh
                                        this.setNetworkAfterDelete = true;
                                    }
                                    if (this.deleting === 0) {
                                        this.isLoading = true;
                                        this.refresh();
                                        this._checkCurrentNetworkAfterDelete();
                                    }
                                },
                                (error) => {
                                    // forcing the deleted items to become untoggled
                                    this.toggle(this.displayedItems[i]);
                                }
                            )
                        );
                    }
                }
            }
        } else {
            this.isDeleteAction = false;
            for (const network of this.items) {
                network.selected = false;
            }
            this.allToggled = false;
            this.selectedNetworkIds = [];
        }
    }

    getTotalSelected() {
        return this.selectedNetworkIds.length;
    }

    public delete(network) {
        const version = this.apiService.getNetworkVersion(network);
        const model = this.apiService.getNetworkModel(network);

        if (version < 7) {
            this.networkService.delete(network);
        } else {
            this.networkServiceV2.deleteResource(model).subscribe((res) => {
                this.logger.log('Delete response', res);
                this.refresh();
            });
        }
    }

    closeActionMenu() {
        this.openIndex = -1;
    }

    sort(sortBy: string) {
        if (this.sorting === sortBy) {
            if (this.ordering === 'ASC') {
                this.ordering = 'DESC';
            } else {
                this.ordering = 'ASC';
            }
        } else {
            this.ordering = 'ASC';
            this.sorting = sortBy;
        }
    }

    getSortClass(id) {
        if (id === this.sorting) {
            return this.ordering;
        } else {
            return '';
        }
    }

    trackById(index, item) {
        return item.id;
    }

    buildNetworkSearchString(item) {
        return `${item.name}|${item.networkGroupName}`;
    }

    o365Optimize(network) {
        this.dialogRef = this.dialogForm.open(O365BreakoutComponent, {
            data: { network: network },
            height: '380px',
            width: '600px',
            autoFocus: false,
        });

        this.dialogRef.afterClosed().subscribe((result) => {
            if (result == null || result['loggingOut'] === undefined) {
                this.isLoading = true;
                this.refresh();
            }
        });
    }

    getNetworkGroupName(network, version) {
        const field = version < 7 ? 'organizationId' : 'networkGroupId';
        const networkGroupId = network[field];

        const name = this.networkGroupMap[networkGroupId] === null ? '--' : this.networkGroupMap[networkGroupId];
        return name;
    }

    formatTime(theDate) {
        return moment(theDate).local().format('M/D/YY h:mm a');
    }

    setNetworkGroupNameFilter(networkGroupId) {
        if (this.networkGroupMap[networkGroupId]) {
            this.selectedNetworkGroupName = 'Network Group';
            this.networkGroupFilter = false;
        } else {
            this.selectedNetworkGroupName = this.networkGroupMap[networkGroupId];
            this.networkGroupFilter = this.networkGroupMap[networkGroupId] || false;
        }
        this.applyNetworkGroupFilter(this.networkGroupFilter);
    }

    applyNetworkGroupFilter(networkGroupId) {
        if (networkGroupId) {
            const networkGroupFilteredItems = [];
            for (const item of this.items) {
                if (item.organizationId === networkGroupId) {
                    networkGroupFilteredItems.push(item);
                }
            }

            this.applyFilter(networkGroupFilteredItems);
        } else {
            this.applyFilter(this.items);
        }
    }

    filtering(type) {
        if (this.filteringBy === type) {
            this.filteringBy = '';
        } else {
            this.filteringBy = type;
        }
    }

    downloadCsv(event: any) {
        const columns = [
            'name',
            'status',
            'networkGroupName',
            'productFamily',
            'productVersion',
            'o365BreakoutCategory',
            'createdAt',
            'provisionedAt',
            'updatedAt',
        ];
        const filename = 'networks';
        const translateStatus = true;

        const csvItems = [];

        for (const item of this.displayedItems) {
            if (item.selected) {
                csvItems.push(item);
            }
        }

        this.toggleMenu(event, -2);
        this.csvDownloadService.download(filename, csvItems, columns, translateStatus);
    }

    openClientMfaForm(network: Network) {
        this.dialogRef = this.dialogForm.open(ClientMfaFormComponent, {
            data: network,
            height: '390px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            if (result === undefined) {
                this.refresh();
            }
        });
    }

    exportYML(network: Network) {
        // @TODO - rewire

        const apiUrl = this.environment.apiUrl;

        const headers = this.setHeaders();
        this.http
            .get(`${apiUrl}/networks/${network.getId()}/blueprint`, {
                headers: headers,
                responseType: 'text',
            })
            .toPromise()
            .then((data) => {
                // convert yaml to blob to download it as a file
                const blob = new Blob([data], { type: 'text/plain' });
                const fileName = network.name.replace(/ /g, '');
                this.fileSaverService.save(blob, fileName + '.yml');
            });
    }

    confirmExport(network: Network) {
        this.dialogRef = this.dialogForm.open(DownloadEntitiesComponent, {
            data: {
                model: network,
                downloadService: this.csvDownloadService,
                currentNetwork: this.currentNetwork,
                downloadAll: false,
                downloadNetwork: true,
                entityDownloadService: this.networkServiceV2.getNetwork.bind(this.networkServiceV2),
                entityType: 'Network',
                fileName: `${network.name}_network`,
                items: [network],
            },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    closeFilters() {
        this.filteringBy = '';
    }

    goToDashboard(network) {
        this.apiService.setCurrentNetwork(network);
        if (this.apiService.getNetworkVersion(network) < 7) {
            this.router.navigate(['/main']);
        }
    }

    getUpgradableVersions(network: NetworkV2) {
        this.upgradeableVersions = this.networkUpgradeService.getUpgradeableVersions(network);
        return this.upgradeableVersions;
    }

    upgrade(network: NetworkV2) {
        this.networkUpgradeService.requestUpgrade(network);
        this.growlerService.show(
            new GrowlerData(
                'success',
                'Success',
                'Upgrade Started',
                'Upgrade was started successfully.<a href="' +
                    this.router.createUrlTree(['/process-executions']).toString() +
                    '">Click here to find out more.</a>'
            )
        );
    }

    restart(network: NetworkV2) {
        this.networkUpgradeService.requestRestart(network);
    }

    resize(network: NetworkV2) {
        this.networkUpgradeService.requestResize(network);
    }

    suspend(network: NetworkV2) {
        this.networkServiceV2.suspend(network.id).subscribe(
            (result) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Suspend Started',
                        'Suspension was started successfully.<a href="' +
                            this.router.createUrlTree(['/process-executions']).toString() +
                            '">Click here to find out more.</a>'
                    )
                );
                return result;
            },
            (httpErrorResponse) => {
                this.growlerService.show(
                    new GrowlerData('error', 'Error suspending network', httpErrorResponse.error.errors[0])
                );
            }
        );
    }

    resume(network: NetworkV2) {
        this.networkServiceV2.resume(network.id).subscribe(
            (result) => {
                this.growlerService.show(
                    new GrowlerData(
                        'success',
                        'Success',
                        'Resume Started',
                        'Resume was started successfully. <a href="' +
                            this.router.createUrlTree(['/process-executions']).toString() +
                            '">Click here to find out more.</a>'
                    )
                );
                return result;
            },
            (httpErrorResponse) => {
                this.growlerService.show(
                    new GrowlerData('error', 'Error resuming network', httpErrorResponse.error.errors[0])
                );
            }
        );
    }

    openJson(item: NetworkV2) {
        this.dialogRef = this.dialogForm.open(JsonViewComponent, {
            data: {
                model: item,
            },
            height: '800px',
            width: '1200px',
        });
    }

    private _getStatusLabel(network, version) {
        // otherwise translate v6 networks
        if (network.status < 300) {
            return 'BUILDING';
        } else if (network.status === 300) {
            return 'PROVISIONED';
        } else if (network.status === 600) {
            return 'UPDATING';
        } else {
            return 'ERROR';
        }
    }

    private async _loadV6networks() {
        return await this.networkService
            .get()
            .pipe(take(1))
            .toPromise()
            .then((networkList) => {
                // Hide deleting networks
                const networks = [];
                for (const network of networkList) {
                    if (network.status < 700 && network.organizationId !== null) {
                        networks.push(new Network(network));
                    }
                }

                this.networksV6 = networks;
            });
    }

    private async _loadV7networks() {
        return await this.networkServiceV2.getNetworksPage({ pageParams: { size: 2000, page: 0 } }).then(
            (networks: NetworkV2[]) => {
                const v2Networks = [];
                for (const v2Network of networks) {
                    // only show v2 networks that are in a living state
                    if (v2Network.status !== 'DELETED') {
                        v2Networks.push(v2Network);
                    }
                }

                this.networksV7 = v2Networks;
            }, // @TODO - better handling - 404's are ERROR but OK if no one has V2 networks yet
            (error) => {
                this.logger.error('Error returning V2 networks for networks component', error);
                this.networksV7 = [];
            }
        );
    }

    // add some additional fields to the network model
    private _process_model(network) {
        const model = this.apiService.getNetworkModel(network);
        const version = this.apiService.getNetworkVersion(network);
        const minVersion = this.apiService.getNetworkMinorVersion(network);
        const patchVersion = this.apiService.getNetworkPatchVersion(network);
        if (version >= 7) {
            model.selected = false;
        }

        model['actionList'] = this._get_actions(model, version);
        model['statusLabel'] = this._getStatusLabel(model, version);
        model['majorVersion'] = version;
        model['version'] = `${version}.${minVersion}.${patchVersion}`;
        model['networkGroupName'] = this.getNetworkGroupName(network, version);
        model['upgradeableVersions'] = this.getUpgradableVersions(network);

        return model;
    }

    // determine what actions we can perform on this network model
    private _get_actions(network, version) {
        const actions = [];
        actions.push('get');

        if (version < 7) {
            actions.push('exportYml');
        }

        if (this.featureService.powerUserEnabled) {
            actions.push('json');
        }

        const orgId = network.organizationId;
        if (this.authorizationService.canDeleteNetwork(network.id, orgId)) {
            // && version < 7
            actions.push('delete');
            this.hasSomeDeletePermission = true;
        } else {
            this.noDeleteAuthCount++;
        }

        // @TODO - evaluate and fix for V7 networks
        if (this.authorizationService.canUpdateNetwork(network.id, orgId) && version < 7) {
            actions.push('update');
            actions.push('o365');
        }

        return actions;
    }

    private _checkCurrentNetworkAfterDelete() {
        // checking if the current network was just deleted
        if (this.setNetworkAfterDelete) {
            // if it was, set the setNetworkAfterDelete flag to false
            this.setNetworkAfterDelete = false;

            // if there is at least one network, set the networkWasUpdated flag to false
            let networkWasUpdated = false;

            // iterating through the list of networks until we find the first network that is not deleting
            for (const network of this.items) {
                if (
                    network.status !== 800 &&
                    network.status !== 'DELETED' &&
                    network.status !== 'DELETING' &&
                    this.deletedNetworkIds.indexOf(network.id) === -1
                ) {
                    // if the current network is not deleting, set it as the current network
                    this.apiService.setCurrentNetwork(network);

                    // marking the networkWasUpdated flag as true
                    networkWasUpdated = true;
                    break;
                }
            }

            // if no networks were found that were not in the process of deleting, set the network to a new network
            if (!networkWasUpdated) {
                this.apiService.setCurrentNetwork(new Network({}));
            }
        }
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Accept', 'application/yml');
        } else {
            return headers;
        }
    }
}
