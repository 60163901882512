<header>
    <div class="overlay"></div>
    <div class="topButtons">
        <div
            (click)="open('Network')"
            (mouseenter)="hover('network')"
            (mouseleave)="hover('')"
            *ngIf="authorizationService.canListNetworks()"
            [ngClass]="{ open: area === 'Network', disabled: billingStatusInvalid }"
            class="clickable main"
            id="NavigationButton"
        >
            <div class="clicked"></div>
            <div class="main status s{{ network.status }}">
                <div class="anim5"></div>
            </div>
            <div class="circle"></div>
            <div class="ha-badge" *ngIf="network.haEnabled"></div>
            <div [hidden]="network.status === 200" class="icon"></div>
            <div [ngClass]="{ open: popup === 'network' }" class="hover">Network Settings</div>
        </div>
        <div
            (click)="open('GettingStarted')"
            (mouseenter)="hover('getting-started')"
            (mouseleave)="hover('')"
            *ngIf="isSelfService && !billingStatusInvalid && authorizationService.canCreateNetworks()"
            [ngClass]="{ open: area === 'getting-started' }"
            class="clickable"
            id="GettingStartedButton"
        >
            <div class="clicked"></div>
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'getting-started' }" class="hover">Quick Start</div>
        </div>
        <div
            (click)="open('Add')"
            (mouseenter)="hover('add')"
            (mouseleave)="hover('')"
            *ngIf="!billingStatusInvalid && authorizationService.canListNetworks() && canCreateAny()"
            class="clickable"
            id="AddButton"
        >
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'add' }" class="hover">Add New</div>
        </div>
        <div
            (click)="open('Search')"
            (mouseenter)="hover('search')"
            (mouseleave)="hover('')"
            *ngIf="!billingStatusInvalid && authorizationService.canListNetworks()"
            [ngClass]="{ open: area === 'Attibute Explorer' }"
            class="clickable"
            id="SearchButton"
        >
            <div class="clicked"></div>
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'search' }" class="hover">Search</div>
        </div>
        <div
            (click)="open('Processes')"
            (mouseenter)="hover('Processes')"
            (mouseleave)="hover('')"
            *ngIf="!billingStatusInvalid && authorizationService.canListNetworks()"
            [ngClass]="{ open: area === 'Network', active: processActive }"
            class="clickable"
            id="ProcessButton"
        >
            <span *ngIf="processActive" class="bubble" ng-reflect-klass="bubble">
                <svg class="circular" viewBox="25 25 50 50">
                    <circle
                        class="path"
                        cx="50"
                        cy="50"
                        fill="none"
                        r="20"
                        stroke="white"
                        stroke-miterlimit="10"
                        stroke-width="7"
                    ></circle>
                </svg>
            </span>
            <div [ngClass]="{ open: popup === 'Processes' }" class="hover">Network Processes</div>
        </div>
    </div>
    <div class="bottomButtons">
        <div
            (click)="open('Super')"
            (mouseenter)="hover('Super')"
            (mouseleave)="hover('')"
            *ngIf="authorizationService.isSupportUser()"
            class="clickable"
            id="SuperButton"
        >
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'Super' }" class="hover">Super User Navigation</div>
        </div>
        <div
            (click)="open('Alerts')"
            (mouseenter)="hover('Notifications')"
            (mouseleave)="hover('')"
            class="clickable"
            id="AlertButton"
            *ngIf="authorizationService.canListNetworks() || authorizationService.canListOrganizations()"
        >
            <div class="circle"></div>
            <div class="icon"></div>
            <div [hidden]="items.length === 0" class="count">
                <span style="display: none">{{ getTotal(items.length) }}</span>
            </div>
            <div [ngClass]="{ open: popup === 'Notifications' }" class="hover">Recent Alerts</div>
        </div>
        <div
            (click)="open('Support')"
            (mouseenter)="hover('Support')"
            (mouseleave)="hover('')"
            [ngClass]="{ open: area === 'Support' }"
            class="clickable"
            id="HelpButton"
        >
            <div class="clicked"></div>
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'Support' }" class="hover">Support</div>
        </div>
        <div
            (click)="open('Billing')"
            (mouseenter)="hover('billing')"
            (mouseleave)="hover('')"
            *ngIf="showBilling"
            [ngClass]="{ open: area === 'Billing' }"
            class="clickable"
            id="BillingButton"
        >
            <div class="clicked"></div>
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'billing' }" class="hover">Billing</div>
        </div>
        <div
            (click)="open('Organization')"
            (mouseenter)="hover('organization')"
            (mouseleave)="hover('')"
            [ngClass]="{ open: area === 'Organization' }"
            *ngIf="authorizationService.canListUserIdentities(currentTenant?.id)"
            class="clickable"
            id="OrgButton"
        >
            <div class="clicked"></div>
            <div class="circle"></div>
            <div class="icon"></div>
            <div [ngClass]="{ open: popup === 'organization' }" class="hover">Organization</div>
        </div>
        <div (mouseleave)="hover('')" class="clickable main" id="ProfileButton">
            <div class="main clickable"></div>
            <div class="circle"></div>
            <div
                (mouseenter)="open('Profile')"
                [ngStyle]="{ 'background-image': 'url(' + photo + ')' }"
                class="icon"
            ></div>
            <div [ngClass]="{ open: popup === 'profile' }" class="menu" id="ProfileMenu">
                <div class="menutitle">{{ usersname }}</div>
                <div *ngIf="!billingStatusInvalid" class="link" id="EditProfile" routerLink="/profile/">
                    Edit Profile
                </div>
                <div *ngIf="!billingStatusInvalid" class="link" id="PersonalSettings" routerLink="/user-settings/">
                    Personal Settings
                </div>
                <div (click)="confirmLogout()" class="link" id="LogoutButton">Logout</div>
            </div>
        </div>
    </div>
</header>
